<template>
  <v-layout>
    <v-flex :xs7="changeCalculator">
      <v-text-field
        @input="(a) => setAmount(a)"
        :label="label || $t('fields.cash')"
        :value="displayAmount"
        :disabled="disabled"
        class="currency"
        autocomplete="off"
        :hide-details="!errorMessages || !errorMessages.length"
        :autofocus="autofocus"
        outlined
        :errorMessages="errorMessages"
      >
        <template v-if="changeCalculator || displayTotal" v-slot:append>
          <span class="grey--text currency">/ {{formatAmount(total)}} {{currency.name}}</span>
        </template>
      </v-text-field>
    </v-flex>
    <v-flex v-if="changeCalculator" xs5>
      <v-layout column class="grey--text pl-3">
        <div class="subtitle-2">{{$t('fields.change')}}</div>
        <div class="title" :class="{'error--text': (amount - total) < 0 }">{{ formatAmount((amount - total)) }} {{ currency.name }}</div>
      </v-layout>
    </v-flex>
  </v-layout>

</template>

<script>
import moneyFormat from '@/mixins/MoneyFormat'
import pricingHelpers from '@/mixins/PricingHelpers'
import { mapGetters } from 'vuex'

export default {
  // EMITTED EVENTS: input
  props: {
    total: Number,
    value: Number,
    currency: Object,
    changeCalculator: Boolean,
    displayTotal: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    autofocus: Boolean,
    label: String
  },
  data () {
    return {
      displayAmount: null,
      amount: null
    }
  },
  computed: {
    ...mapGetters({
      getCurrency: 'configuration/getCurrency'
    })
  },
  methods: {
    setAmount (amount) {
      this.displayAmount = null
      if (typeof amount !== 'undefined' && amount !== '') {
        this.amount = this.parsePrice(amount)
        this.$nextTick(() => {
          this.displayAmount = this.sanitizePrice(amount, this)
        })
      } else {
        this.amount = null
        this.$nextTick(() => {
          this.displayAmount = null
        })
      }
      this.$emit('input', this.amount)
    }
  },
  created () {
    if (this.value) {
      this.displayAmount = this.setAmount(this.value.toString())
      this.$emit('input', this.amount)
    }
  },
  mixins: [
    pricingHelpers,
    moneyFormat
  ]
}
</script>
